<template>
  <v-container class="white fill-height">
    <v-row>
      <v-col cols="12" class="mx-auto">
        <v-img
          class="pa-4 mx-auto"
          width="200px"
          :src="require('@/assets/logo.png')"
          contain
        ></v-img>
      </v-col>
      <loader v-if="loading"></loader>
      <v-row v-else-if="error">
        <v-col cols="12" class="mx-auto text-center">
          <h1>{{ $t("register.error") }}</h1>
        </v-col>
        <v-col cols="12" class="mx-auto text-center">
          <v-icon size="100" color="error">mdi-close-circle-outline</v-icon>
        </v-col>
        <v-col cols="12" class="mx-auto text-center">
          <h5>{{ $t("register.errorText") }}</h5>
        </v-col>
        <v-col cols="12" class="mx-auto text-center">
          <v-btn link primary text :to="{ name: 'login' }">
            <v-icon>mdi-login</v-icon>
            {{ $t("login.login") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="mx-auto text-center">
          <h1>{{ $t("register.thankYou") }}</h1>
        </v-col>
        <v-col cols="12" class="mx-auto text-center">
          <v-icon size="100" color="success">mdi-check-circle-outline</v-icon>
        </v-col>
        <v-col cols="12" class="mx-auto text-center">
          <h5>{{ $t("register.confirmRegistration") }}</h5>
        </v-col>
        <v-col cols="12" class="mx-auto text-center">
          <v-btn link primary text :to="{ name: 'login' }">
            <v-icon>mdi-login</v-icon>
            {{ $t("login.login") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    Loader: () => import("@/components/core/Loader")
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      loading: "users/loading",
      error: "users/error"
    })
  },
  async created() {
    let _this = this;
    await _this.$store.dispatch("users/confirmRegistration", _this.$route.params.id);
  }
};
</script>
